import Web3 from "web3";
import i18n from "@/utils/lang";
import { Toast } from "vant";
// import { resolve } from 'core-js/fn/promise'

export default new (class {
  /**
   * ETH请求实例
   * @returns {Promise<Eth>}
   * @private
   */
  async _eth() {
    const provider = window.ethereum;
    if (provider) {
      // Toast('provider')
      return new Web3(provider).eth;
    } else {
      // Toast('providerErro')
      return Promise.reject(i18n.t("web3.no_install"));
    }
  }

  /**
   * 链接钱包
   * @returns {Promise<Modules.Eth>}
   */
  async link() {
    const eth = await this._eth();
    try {
      const accounts = await eth.requestAccounts();
      // Toast('account:' + accounts[0])
      return eth;
    } catch (err) {
      // Toast('err')
      const error = err.innerError || err;
      switch (error.code) {
        case 4001:
          return Promise.reject(i18n.t("web3.link_error.reject"));
        case -32002:
          return Promise.reject(i18n.t("web3.link_error.waiting"));
        default:
          return Promise.reject(i18n.t("web3.link_error.fail"));
      }
    }
  }
  async transferEth(toAddress, num) {
    const eth = await this._eth();
    await this.link();
    const Accounts = await eth.getAccounts();
    const fromAddress = Accounts[0];
    const value = Web3.utils.toWei(num, "ether");
    console.log(fromAddress, "fromAddress");
    console.log(toAddress, "toAddress");
    return new Promise((resolve) => {
      try {
        let res = eth.sendTransaction({
          from: fromAddress, // 发送方账户
          to: toAddress, // 收款地址
          value: value, // 转换为 Wei 单位
          // gas: Web3.utils.toHex("200000"),
          // gasPrice: Web3.utils.toHex(eth.getGasPrice()), // 转换为 Wei 单位
        });
        resolve(res);
        console.log(res, "成功");
      } catch (error) {
        resolve(error);
        console.log(error, "失败");
      }
    });
  }
  // 切换网络
  async ethSwitchChain() {
    let CHAIN_ID = Web3.utils.toHex(0x38);
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: CHAIN_ID }],
        });
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: CHAIN_ID }],
        });
      } catch (err) {

        console.log(err, '输出错误')
        switch (err.code) {
          case 4001:
            return Promise.reject(i18n.t("您已取消切换网络"));
          case 4902:
          case -32603:
            return Promise.reject(i18n.t("没有添加BSC网络"));
          default:
            return Promise.reject(i18n.t("网络切换失败"));
        }
      }
    }
  }
  //   获取账户余额
  async getBalance() {
    const eth = await this._eth();
    const Accounts = await eth.getAccounts();
    const fromAddress = Accounts[0];
    try {
      let a = await eth.getBalance(fromAddress);
      let balance = Web3.utils.fromWei(a, "ether");
      return balance;
      // console.log(balance, 'balance')
    } catch (error) {
      console.log(error)
      return error;
    }
    //    eth.getBalance(fromAddress).then(tx=>{
    //      const balanceInEther = Web3.utils.fromWei(tx, 'ether');
    //      console.log(balanceInEther,'balanceInEther')
    // })

    //     eth.getBalance(fromAddress)
    //     .then(balance => {
    //     console.log(balance,'balance');
    //   })
    //   .catch(error => {
    //     console.error('Error fetching balance:', error);
    //   });

    // const ts_ = this
    //  this.address().then(res => {
    //     console.log(res,'res')
    // const eth =  ts_._eth();
    // const balance = eth.getBalance(res)
    // console.log(balance,'balace')
    // })
  }

  /**
   * 获取第一个地址
   * @returns {Promise<string|string>}
   */
  async address() {
    const eth = await this._eth();
    const accounts = await eth.getAccounts();
    return accounts[0] || "";
  }

  /**
   * 签名
   * @param address
   * @param str
   * @returns {Promise<*>}
   */
  async sign(address, str) {
    const eth = await this._eth();
    try {
      return await eth.personal.sign(str, address, "");
    } catch (err) {
      const error = err.innerError || err;
      switch (error.code) {
        case 4001:
          return Promise.reject(i18n.t("web3.sign_error.reject"));
        default:
          return Promise.reject(i18n.t("web3.sign_error.fail"));
      }
    }
  }
})();
