<!--
 * @Author: qinlifang 1171533972@qq.com
 * @Date: 2023-09-17 02:54:04
 * @LastEditors: 1171533972@qq.com
 * @LastEditTime: 2024-04-02 10:33:49
 * @FilePath: \cyberlink-h5\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import i18n from "@/utils/lang";
import Bridge from "@/utils/bridge";
import { Toast } from "vant";

export default {
  components: { Toast },
  data() {
    return {};
  },
  created() {

    },

  mounted() {
     let language = Bridge.getLanguage();
      if (language) {
        localStorage.setItem("languageSet", language);
         this.$i18n.locale = language;

      }

    if (window.location.pathname != "/notice") {
      try {
        const token = "bearer " + Bridge.getToken();
        if (token) localStorage.setItem("token", token);
      } catch (error) {
        this.$router.push("/error");
      }
    }
  },
};
</script>

<style lang="less"></style>
